/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import { Button } from '../button';
import SignaturePad from 'signature_pad';
import { buildDims, styles, DEFAULT_FONT_SIZE } from './styles';
import Row from '../row';
import { Icon, Input, Modal } from 'antd';
import { useScreen } from '../hooks/useScreen';
import { useParent } from '../hooks/useParent';
import { UltimateTextToImage } from 'ultimate-text-to-image';
import { wait } from '../../actions/utils';

const DISCLAIMER = {
  customer: 'By typing your name below, you are signing this application electronically. You confirm that the contents of this shipment match weight records entered above, and you agree to take custody of this shipment. You agree that your electronic signature is the legal equivalent of your manual signature on this application.',
  driver: 'By typing your name below, you are signing this application electronically. You agree that your electronic signature is the legal equivalent of your manual signature on this application.',
}

const SigpadEdit = props => {
  const [sig, setsig] = useState();
  const [state, setState] = useState({
    strokes: 0,
    hasLoadedImage: false,
    fullscreen: true,
  })

  const [edits, setedits] = useState({
    name: get(props, 'name', ''),
    emails: get(props, 'emails', ''),
    tags: get(props, 'tags', ''),
  })

  const container = useRef();
  const parent = useParent(container.current);
  const { windowSize, isMobile } = useScreen();

  const getDefaultFontSize = () => {
    return isMobile ? DEFAULT_FONT_SIZE - 30 : DEFAULT_FONT_SIZE;
  }

  const canRef = useRef();

  const width = state.fullscreen ? windowSize.innerWidth : parent.width;

  const sizeCanvas = (dimen) => {
    const ratio = 1;// Math.max(window.devicePixelRatio || 1, 1);
    canRef.current.width = dimen.width * ratio;
    canRef.current.height = dimen.height * ratio;
    canRef.current.getContext("2d").scale(ratio, ratio);
    sig.clear(); // otherwise isEmpty() might return incorrect value
  }

  const sigIsEmpty = () => {
    return !sig ? true : sig.isEmpty();
  }

  const isEmpty = sigIsEmpty() && !state.strokes;
  const submitdisabled = !edits.name;
  const fontSize = (() => {
    if (isEmpty) {
      const measure = (getDefaultFontSize() * 0.65);
      const isGreater = edits.name.length * measure >= width;
      const max = width / measure;
      return isGreater ? (max / edits.name.length) * getDefaultFontSize() : getDefaultFontSize();
    }
    return getDefaultFontSize();
  })();

  const getSigImage = async () => {
    const dims = buildDims();
    if (sigIsEmpty()) {
      const fsize = dims.width / width;
      return new UltimateTextToImage(edits.name, {
        width: parseInt(dims.width),
        height: parseInt(dims.height),
        maxWidth: parseInt(dims.width),
        maxHeight: parseInt(dims.height),
        fontFamily: "Homemade Apple",
        fontColor: "#000",
        fontSize: fontSize * fsize,
        lineHeight: fontSize * fsize,
        valign: "middle",
        // autoWrapLineHeightMultiplier: 1.2,
        // margin: 20,
        // align: "center",
        // minFonize: 10,
        // borderColor: 0xFF000099,
        // borderSize: 2,
        // backgroundColor: "#c2c2c2",
        // underlineColor: "#00FFFF33",
        // underlineSize: 2,
      }).render().toDataUrl();
    } else {
      const image = sig.toDataURL();
      sizeCanvas(dims);
      // canRef.current.width = dims.width;
      // canRef.current.height = dims.height;
      // canRef.current.getContext('2d').scale(1, 1);
      sig.fromDataURL(image, dims);
      await wait(100);
      return sig.toDataURL();
    }
  }

  const save = () => {
    const disclaimerType = get(props, 'disclaimerType', 'driver');
    Modal.confirm({
      title: 'DISCLAIMER',
      content: get(DISCLAIMER, disclaimerType, DISCLAIMER.driver),
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        const result = await getSigImage();
        props.onSave && props.onSave({ image: result, ...edits });
      },
    })
  }

  const clear = () => {
    if (sig && sig.isEmpty() && props.onClose) {
      return props.onClose();
    }
    sig.clear();
    updateStrokes(-1);
  }

  const updateStrokes = (strokes = state.strokes) => {
    setState(s => ({ ...s, strokes: strokes + 1 }));
  }

  const togglefullscreen = () => {
    setState(s => ({ ...s, fullscreen: !s.fullscreen }));
  }

  const changeName = e => {
    const name = e.target.value;
    setedits(s => ({ ...s, name }));
    if (!sigIsEmpty()) { clear() }
  }

  const changeEmails = e => {
    const emails = e.target.value;
    setedits(s => ({ ...s, emails }));
  }

  const changeTags = e => {
    const tags = e.target.value;
    setedits(s => ({ ...s, tags }));
  }

  useEffect(() => {
    setsig(new SignaturePad(canRef.current));
  }, []);

  useEffect(() => {
    if (!!sig) { 
      sig.addEventListener('endStroke', () => {
        updateStrokes();
      });

      return () => {
        try {
          sig.off()
        } catch (err) {
          console.error(err);
        }
      };
    }
  }, [sig]);

  useEffect(() => {
    if (!!sig) {
      const canvadims = buildDims(width - (state.fullscreen ? 20 : 0));
      sizeCanvas(canvadims);
      if (props.image && !state.strokes) {
        sig.fromDataURL(props.image, canvadims);
      }
    }
  }, [sig, width]);

  const showEmails = get(props, 'showEmails', false);
  const showTags = get(props, 'showTags', false);
  const manifest = get(props, 'manifest');

  return (
    <div ref={container} css={css(styles.editcontainer({ width }), state.fullscreen && styles.fullscreen)}>
      {state.fullscreen && <div css={css(styles.fsSigText)}>{get(props, 'sigText', `Signature`)}</div>}
      
      <div className="inputContainer">
        <label>Print Name</label>
        <Input value={edits.name} onChange={changeName} />
      </div>

      {showEmails && <div className="inputContainer">
        <label>Emails to send signed copy (comma separated)</label>
        <Input value={edits.emails} onChange={changeEmails} />
      </div>}

      {showTags && <div className="inputContainer">
        <label>Rejected Package Tags</label>
        <Input value={edits.tags} onChange={changeTags} />
      </div>}
      
      <div css={css(styles.canvascontainer)}>
        <canvas ref={canRef} />
        {isEmpty && <div className='textToSig' style={{ fontSize }}>{edits.name}</div>}
      </div>

      <Row css={css(styles.buttonContainer, state.fullscreen && styles.buttonContainerFS)}>
        {!!manifest && <Button outline hoverText="View Manifest" icon="file-protect" title="View Manifest" onClick={() => window.open(manifest, '_blank')} />}
        <Button disabled={isEmpty} hoverText="Save" icon="save" onClick={save} />
        <Button hoverText="Sign with finger" icon="edit" onClick={() => updateStrokes()} />
        {/* <Button hoverText="Full Screen" icon={state.fullscreen ? "fullscreen-exit" : "fullscreen"} onClick={togglefullscreen} /> */}
        <Button hoverText="Clear" icon="close" onClick={clear} />
      </Row>

      {state.fullscreen && <Row center css={css(styles.fullscreenbottomrow)}>
        <Button disabled={submitdisabled} title="Submit" icon="save" onClick={save} />
      </Row>}
    </div>
  )
}

const SigWait = props => {
  const [state, setState] = useState({
    show: false,
  })

  useEffect(() => {
    setTimeout(() => {
      setState(s => ({ ...s, show: true }));
    }, 250);
  }, []);

  if (!state.show) { return <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon type="loading" /></div> }

  return <SigpadEdit {...props} />
}

const SigpadDisplay = props => {
  const fixImage = (img) => {
    return img == '_' ? undefined : img;
  }

  const [state, setState] = useState({
    edit: false,// true,// !props.image ? true : false,
    name: '',
    ...props,
    image: fixImage(props.image),
  })

  const container = useRef();
  const parent = useParent(container.current);

  const onSave = (result) => {
    setState(s => ({ ...s, edit: false, ...result }));
    props.onSave && props.onSave(result);
  }

  useEffect(() => {
    if (props.image) {
      setState(s => ({ ...s, image: fixImage(props.image) }));
    }
    if (props.name) {
      setState(s => ({ ...s, name: props.name }));
    }
  }, [props.image, props.name]);

  return (
    <div ref={container} css={css(styles.editcontainer(parent))}>
      <div className='namedisplay'>{state.name}</div>
      <div css={css(styles.canvascontainer)} className="display" style={buildDims(parent.width)}>
        {!!state.image && <img style={buildDims(parent.width)} src={state.image} />}
      </div>
      <div css={css(styles.buttonContainer)}>
        <Button disabled={props.disabled} icon={state.image ? "edit" : "plus"} hoverText={state.image ? 'Edit Signature' : 'Add Signature'} onClick={() => setState(s => ({ ...s, edit: !s.edit }))} />
      </div>
      <Modal 
        visible={!!state.edit}
        width={'100%'}
        height={'100%'}
        style={{ top: 0, height: '100%' }}
        footer={null}
        destroyOnClose={true}
      >
        <SigWait {...props} onSave={onSave} onClose={() => setState(s => ({ ...s, edit: false }))} />
      </Modal>
    </div>
  )
}

const TYPES = {
  display: SigpadDisplay,
}

export const Sigpad = props => {
  const type = get(props, 'type', 'display')

  const Comp = get(TYPES, type);

  return <Comp {...props} />
}